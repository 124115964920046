import residential from "../../images/residential.jpeg";
import ev from "../../images/ev-charging.jpeg";
import sonos from "../../images/sonos.jpeg";
import homeTheater from "../../images/home-theater.jpeg";
import renovations from "../../images/renovations.jpeg";
import homeAutomation from "../../images/home-automation.jpeg";
import generators from "../../images/generators.jpeg";
import fireProtection from "../../images/fire-protection.jpeg";

export type ServiceInfo = {
  title: string;
  imgUrl: string;
  imgAlt: string;
  description: string;
};

export const servicesList: ServiceInfo[] = [
  {
    title: "New Construction",
    imgUrl: residential,
    imgAlt: "New Construction",
    description:
      "We work with both contractors and home owners on new builds and additions.",
  },
  {
    title: "EV Charging",
    imgUrl: ev,
    imgAlt: "EV Charger",
    description:
      "We've installed dozens of ev chargers for businesses and homes.",
  },
  {
    title: "Home Automation",
    imgUrl: homeAutomation,
    imgAlt: "Home Automation",
    description:
      "We can help setup home automation for lights, security systems and IOT devices",
  },
  {
    title: "Home Theater",
    imgUrl: homeTheater,
    imgAlt: "Home Theater",
    description:
      "We've installed entire home theater systems with projectors, surrond sound and lighting",
  },
  {
    title: "Sonos Systems",
    imgUrl: sonos,
    imgAlt: "Sonos Systems",
    description:
      "Looking to get a sonos system setup for your house? We've installed large and small systems for homes.",
  },
  {
    title: "Renovations",
    imgUrl: renovations,
    imgAlt: "Renovations",
    description: "We here to help with any renovation projects you have.",
  },
  {
    title: "Generators",
    imgUrl: generators,
    imgAlt: "Generator",
    description:
      "We've worked on generator installation to keep your house up and running during power outages",
  },
  {
    title: "Fire Protection",
    imgUrl: fireProtection,
    imgAlt: "Fire Protection",
    description:
      "We are here to offer our expertise on keeping your home safe from electrical fires",
  },
];
