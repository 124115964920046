import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Jumbotron, Stack, QuoteForm } from "../../components";
import { Helmet } from "react-helmet";

const Quote = () => {
  return (
    <Container fluid>
      <Helmet>
        <title>Quote - TJ Mullen Electric</title>
        <meta name="og:title" content="Quote - TJ Mullen Electric" />
        <meta
          name="description"
          content="Fill out the form and get a free quote from us!"
        />
        <meta
          name="og:description"
          content="Fill out the form and get a free quote from us!"
        />
      </Helmet>
      <Jumbotron
        header="GET A QUOTE"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Stack
              direction="column"
              space={100}
              sx={{ marginBottom: "100px", marginTop: "100px" }}
            >
              <QuoteForm />
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Quote };
