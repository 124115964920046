import React from "react";
import { useRef } from "react";
import {
  Jumbotron as BootstrapJumbotron,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { QuoteButton } from "../QuoteButton";
import { Stack } from "../Stack";

import { useParallax } from "../../hooks";

import "./jumbotron.scss";

interface IJumbotronProps {
  header?: string;
  showButton?: boolean;
  message?: string;
  size?: string;
}

const defaultMessage = `TJ Mullen Electric is a certified electrician provider for the Greater Boston Area, Cape Cod and North East. 
We are here to help you with all your electrical needs with upfront and honest pricing.`;
// const defaultMessage = `TJ Mullen Electric is the trusted provider of electric services throughout the Greater Boston area, Cape Cod and
// all of Eastern Massachusetts.`;

const Jumbotron = (props: IJumbotronProps) => {
  const {
    header = "WELCOME",
    showButton = true,
    message = defaultMessage,
    size = "large",
  } = props;

  const jumbotronElement = useRef<HTMLDivElement | null>(null);
  useParallax(jumbotronElement.current, size === "large");

  return (
    <BootstrapJumbotron
      className={`jumbotron ${size}`}
      fluid
      ref={jumbotronElement}
    >
      <div className="overlay">
        <Container>
          <Row className="main-content">
            <Col>
              <Row bsPrefix="row d-none d-sm-flex">
                <Col>
                  <Stack direction="row" space={30}>
                    <h1>{header}</h1>

                    {showButton && (
                      <div className="button-col">
                        <QuoteButton variant="danger" />
                      </div>
                    )}
                  </Stack>
                  <Row>
                    <Col md={9} xs={12}>
                      <h2>{message}</h2>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row bsPrefix="row d-xs-flex d-sm-none">
                <Col>
                  <Stack direction="column" space={5}>
                    <h1>{header}</h1>
                    <Row>
                      <Col md={9} xs={12}>
                        <h2>{message}</h2>
                      </Col>
                    </Row>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </BootstrapJumbotron>
  );
};

export { Jumbotron };
