import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga";

import { gaTracking } from "../../services";
import { QuoteModal } from "../QuoteModal";

import "./QuoteButton.scss";
interface QuoteButtonProps {
  variant?: string;
  size?: "sm" | "lg" | undefined;
}

const QuoteButton: React.FC<QuoteButtonProps> = ({
  variant = "primary",
  size = "lg",
  children = "Get A Quote",
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // gaTracking.event("user", "quote_button_clicked", "quote button");
    setShow(true);
  };

  return (
    <>
      <Button
        className="quote-button"
        variant={variant}
        size={size}
        onClick={handleShow}
      >
        {children}
      </Button>

      {show && <QuoteModal show={show} onHide={handleClose} />}
    </>
  );
};

export { QuoteButton };
