import React from "react";
import { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { useParallax } from "../../hooks";
import {
  Jumbotron,
  InteractionCard,
  Stack,
  QuoteButton,
  ServicesSection,
} from "../../components";

import crestLogo from "../../images/crest-logo-small.png";
import residential from "../../images/residential.jpeg";
import gallery from "../../images/gallery-1-small.jpeg";

import "./home.scss";

const Home = () => {
  const fullWidthElement = useRef<HTMLDivElement | null>(null);
  useParallax(fullWidthElement.current, true);

  return (
    <Container fluid>
      <Helmet>
        <title>TJ Mullen Electric</title>
        <meta name="og:title" content="TJ Mullen Electric" />
        <meta name="description" content="TJ Mullen Electric" />
        <meta
          name="og:description"
          content="TJ Mullen Electric provides trusted eletrical work for the Greater Boston and Eastern Massachusetts"
        />
      </Helmet>
      <Jumbotron />
      <Container className="page-container">
        <ServicesSection />
        {/* <div className="fullwidth-row home-row" ref={fullWidthElement}>
          <div className="fullwidth-overlay"></div>
          <Row className="row quote-row">
            <Col>
              <div className="quote-wrapper">
                <h3>LETS FIND THE RIGHT EQUIPMENT FOR YOUR JOB</h3>
                <p>We're ready to get started!</p>
                <QuoteButton />
              </div>
            </Col>
          </Row>
        </div> */}
        <Row className="home-row">
          <Col>
            <h3
              className="text-primary"
              style={{ marginBottom: "0", fontWeight: "bold" }}
            >
              EXPLORE OUR SITE
            </h3>
            <p className="sub-header">gallery - about - contact us</p>
            <Stack
              direction="row"
              space={30}
              sx={{ marginLeft: "auto", marginRight: "auto", padding: "2rem" }}
            >
              <div>
                <InteractionCard
                  title="ABOUT"
                  href="/about"
                  imgAlt="About Us"
                  imgUrl={crestLogo}
                />
              </div>
              <div>
                <InteractionCard
                  title="GALLERY"
                  href="/gallery"
                  imgAlt="TJ Mullen Gallery"
                  imgUrl={gallery}
                />
              </div>
              <div>
                <InteractionCard
                  title="CONTACT US"
                  href="/contact"
                  imgAlt="Contact Us"
                  imgUrl={residential}
                />
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Home };
