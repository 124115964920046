import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { Jumbotron } from "../../components";
import { Stack } from "../../components/Stack";

import "./about.scss";

const About = () => {
  return (
    <Container fluid>
      <Helmet>
        <title>About Us - TJ Mullen Electric</title>
        <meta name="og:title" content="About Us - TJ Mullen Electric" />
        <meta
          name="description"
          content="Learn about how TJ Mullen Electric started!"
        />
        <meta
          name="og:description"
          content="Learn about how TJ Mullen Electric started!"
        />
      </Helmet>
      <Jumbotron
        header="ABOUT US"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Stack direction="column" space={80}>
          <div>
            <Row>
              <Col xs={12} md={7}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  OUR STORY
                </h3>
                <p className="sub-header">founded in 2020</p>
                <p className="description-info">
                  Tyler J. Mullen <br />
                  Owner/Chief Operating Officer of TJ Mullen Electric
                  <br /> <br />
                  A knowledgeable licensed electrician, Tyler has been servicing
                  both residential and commercial clients since 2014. He is
                  experienced in completing ground-up electrical installations,
                  renovations, repairs and maintenance in private homes,
                  corporate spaces and plant facilities. Tyler has vast
                  knowledge of electrical code and has the ability to identify,
                  analyze and solve problems with various electrical controls
                  and systems. <br /> <br /> A graduate of Boston College High
                  School, Tyler studied Electrical Engineering at the University
                  of Maine, Orono. He resides in Hanover, MA with his wife
                  Paige, a registered nurse at Tufts Medical Center. Tyler takes
                  pride in the fact that his business is growing steadily
                  through satisfied customer referrals and looks forward to
                  meeting your specific needs.
                </p>
              </Col>
              <Col md={3} className="d-none d-md-block"></Col>
            </Row>
          </div>
        </Stack>
      </Container>
    </Container>
  );
};

export { About };
