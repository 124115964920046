import * as React from "react";
import { Modal } from "react-bootstrap";

import { QuoteForm } from "../QuoteForm";

import "./QuoteModal.scss";
interface QuoteModalProps {
  show: boolean;
  onHide: () => void;
}

const QuoteModal = ({ show, onHide }: QuoteModalProps) => {
  return (
    <Modal size="xl" show={show} centered onHide={onHide} animation={false}>
      <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
      <Modal.Body>
        <QuoteForm />
      </Modal.Body>
    </Modal>
  );
};

export { QuoteModal };
