import * as React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { FormSteps } from "./FormSteps";
import { FormSuccess } from "./FormSuccess";

import "./QuoteForm.scss";

const QuoteForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOnSuccess = () => {
    setShowSuccess(true);
  };

  return (
    <Row>
      <Col>
        {showSuccess && <FormSuccess />}
        {!showSuccess && <FormSteps onSuccess={handleOnSuccess} />}
      </Col>
    </Row>
  );
};

export { QuoteForm };
