import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { ServiceCard, Stack } from "../../components";

import "./ServicesSection.scss";

import residential from "../../images/residential.jpeg";
import commercial from "../../images/commericial.jpeg";
import lighting from "../../images/outdoor-lighting.jpeg";
import homeTheater from "../../images/home-theater-room.jpeg";

interface ServiceCardInfo {
  title: string;
  description: string;
  photoUrl: string;
  altText: string;
}

const services: ServiceCardInfo[] = [
  {
    title: "RESIDENTIAL",
    description: `We routinely work with indviduals looking to maintain, 
    upgrade or replace their current electrical systems in their house. We have extensive
    knowledge in setting your home up for EV car charging.`,
    photoUrl: residential,
    altText: "TJ Mullen Residential",
  },
  {
    title: "COMMERCIAL",
    description: `We work with big and small contractors and are ready to tackle any . From restaurants to new building wiring 
    we are able to handle any size job`,
    photoUrl: commercial,
    altText: "TJ Mullen Commerical",
  },
  {
    title: "AUDIO/VIDEO",
    description: `Whether you need to mount your TV or are looking to create an entire home
    theater experience, we are here for you. We have the expertise and are eager to help guide
    you to that perfect spot to watch the game, movie or show`,
    photoUrl: homeTheater,
    altText: "TJ Mullen Audio Video",
  },
  {
    title: "LIGHTING",
    description: `Creating an outdoor lighting experience can drastically change your home. We've
    worked on a number of large scale outdoor setups as well as simple flood light installations. We
    are happy to help you create the space you envision`,
    photoUrl: lighting,
    altText: "TJ Mullen Lighting",
  },
];

const ServicesSection = () => {
  return (
    <>
      <h3
        className="text-primary"
        style={{ fontWeight: "bold", marginBottom: "0" }}
      >
        OUR SERVICES
      </h3>
      <p className="sub-header">RESIDENTIAL - COMMERICIAL - AUDIO/VIDEO</p>
      <Row>
        <Col xs={12} md={8}>
          <p className="description-info">
            At TJ Mullen Electric, we focus on exceptional service for any of
            your electrical needs. Our goal is to form lasting relationships
            with our customers. We take pride in our work and want to ensure
            that you are educated on all of the options to get your work done.
            We offer up front and honest pricing that will help you make a
            decision on your project. At TJ Mullen Electric no job is too big or
            too small.
          </p>
          <Link to="/services">{`View Our Full List of Services`}</Link>
        </Col>
      </Row>

      <Row bsPrefix="row d-sm-flex d-md-none service-row">
        <Stack
          className="vertical-services"
          direction="column"
          space={20}
          sx={{ padding: "10px", marginLeft: "auto", marginRight: "auto" }}
        >
          {services.map((service: ServiceCardInfo) => {
            return (
              <ServiceCard
                title={service.title}
                description={service.description}
                photoUrl={service.photoUrl}
                altText={service.altText}
              />
            );
          })}
        </Stack>
      </Row>

      <Row bsPrefix="row d-none d-md-flex service-row">
        <Stack
          direction="row"
          space={20}
          sx={{ padding: "10px", margin: "10px" }}
        >
          {services.map((service: ServiceCardInfo) => {
            return (
              <ServiceCard
                title={service.title}
                description={service.description}
                photoUrl={service.photoUrl}
                altText={service.altText}
              />
            );
          })}
        </Stack>
      </Row>
    </>
  );
};

export { ServicesSection };
