import React from "react";
import { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { ImPhone } from "react-icons/im";
import { Container, Navbar, Nav } from "react-bootstrap";

import { gaTracking } from "../../services";

import { useIntersectionObserver } from "../../hooks";
import { Stack } from "../Stack";
import { QuoteButton } from "../QuoteButton";

import "./navbar.scss";

import logo from "../../images/Logos/stock-logo.png";

const NavBar = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      gaTracking.pageView();
    });
  }, [history]);

  const navBarEl = useRef<HTMLDivElement | null>(null);

  const addBg = (navBar: HTMLDivElement | null, bgClass: string) => {
    if (navBar === null) {
      return;
    }
    navBar.classList.add(bgClass);
  };

  const removeBg = (navBar: HTMLDivElement | null, bgClass: string) => {
    if (navBar === null) {
      return;
    }
    navBar.classList.remove(bgClass);
  };

  const handleJumboAppearing = () => {
    removeBg(navBarEl.current, "dark-background");
  };

  const handleJumboDisappearing = () => {
    addBg(navBarEl.current, "dark-background");
  };

  const handleOnToggle = (expanded: boolean) => {
    if (navBarEl.current === null) {
      return;
    }

    if (expanded) {
      addBg(navBarEl.current, "open-background");
      return;
    }

    removeBg(navBarEl.current, "open-background");
  };

  useIntersectionObserver(
    ".jumbotron",
    handleJumboAppearing,
    handleJumboDisappearing
  );

  return (
    <Navbar
      ref={navBarEl}
      variant="dark"
      bg="clear"
      fixed="top"
      expand="lg"
      collapseOnSelect
      onToggle={handleOnToggle}
    >
      <Container>
        <Stack direction="column" sx={{ width: "100%" }} space={0}>
          <Container>
            <Stack
              direction="row"
              space={10}
              sx={{ marginLeft: "auto", alignItems: "center" }}
            >
              <div className="phone-wrapper">
                <a className="phone" href="tel:+1-781-686-6259">
                  <ImPhone fill="white" /> 781.686.6259
                </a>
              </div>
              <QuoteButton size="sm">Get A Quote</QuoteButton>
            </Stack>
          </Container>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="TJ Mullen Logo" />
            </Navbar.Brand>

            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav className="ml-auto" activeKey={location.pathname}>
                <Nav.Item>
                  <Nav.Link eventKey="1" as={NavLink} to="/" exact>
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2" as={NavLink} to="/gallery">
                    Gallery
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3" as={NavLink} to="/services">
                    Services
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4" as={NavLink} to="/about">
                    About
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="5" as={NavLink} to="/contact">
                    Contact Us
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Stack>
      </Container>
    </Navbar>
  );
};

export { NavBar };
