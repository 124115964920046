import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

import "./ImageCard.scss";

interface ImageCardProps {
  title: string;
  href: string;
  imgUrl: string;
  imgAlt: string;
  layoutId: string;
  onClick: (layoutId: string) => void;
  isOpen?: boolean;
}

const ImageCard = ({
  title,
  href,
  imgAlt,
  imgUrl,
  layoutId,
  onClick,
  isOpen,
}: ImageCardProps) => {
  const newClass = isOpen ? "open" : "closed";

  const showTitleAndOverlay = title !== "" && !isOpen;

  return (
    <div className={`card-content-container ${newClass}`}>
      <Card
        className="image-card card-content"
        onClick={() => onClick(layoutId)}
      >
        <Card.Body>
          <motion.img
            alt={imgAlt}
            className="image-card-image"
            src={imgUrl}
            layoutId={`card-${layoutId}`}
          />
          {showTitleAndOverlay && (
            <>
              <div className="image-card-overlay"></div>
              <Row className="image-card-details">
                <Col>
                  <h3>{title}</h3>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export { ImageCard };
