import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { Stack } from "../Stack";

import logo from "../../images/Logos/stock-logo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <Container fluid className="fluid-footer">
      <Container>
        <Row style={{ paddingTop: "20px" }}>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <img alt="logo" className="logo" src={logo} />
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <h5>
                <Link className="quote" to="/contact">
                  Contact Us
                </Link>
              </h5>
              <Row>
                <Col>
                  <Stack direction="column" space={10}>
                    <Link className="quote" to="/quote">
                      Get A Quote
                    </Link>
                    <a className="phone" href="tel:+1-781-686-6259">
                      781.686.6259
                    </a>
                    <a className="address" href="https://maps.google.com/?q=">
                      TJ Mullen Electric Address
                    </a>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="footer-column-wrapper">
              <Row>
                <Col xs={12}>
                  <div>
                    <h6 style={{ color: "#fff" }}>Follow Us</h6>
                    <a
                      title="facebook page"
                      className="logo facebook-anchor"
                      href="https://www.facebook.com/"
                    ></a>
                    <a
                      title="instagram page"
                      className="logo instagram-anchor"
                      href="https://www.instagram.com/"
                    ></a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Footer };
