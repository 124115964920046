import * as React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Jumbotron, Stack, QuoteForm } from "../../components";
import { Helmet } from "react-helmet";

import "./contact.scss";

const Contact = () => {
  return (
    <Container fluid>
      <Helmet>
        <title>Contact Us - TJ Mullen Electric</title>
        <meta name="og:title" content="Contact Us - TJ Mullen Electric" />
        <meta
          name="description"
          content="Reach out to us to get a free quote or fill out a quote form and well contact you!"
        />
        <meta
          name="og:description"
          content="Reach out to us to get a free quote or fill out a quote form and well contact you!"
        />
      </Helmet>
      <Jumbotron
        header="CONTACT US"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Row>
              <Col xs={12} md={7}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  REACH OUT
                </h3>
                <p className="sub-header">phone - email - quote</p>
                <p className="description-info">
                  We'd love to hear from you - you can reach us directly or fill
                  out the form below to get a free quote!
                </p>
              </Col>
            </Row>
            <Stack
              direction="column"
              space={100}
              sx={{ marginBottom: "100px", marginTop: "30px" }}
            >
              <Row>
                <Col xs={12} lg={6} className="contact-card-col">
                  <Card className="contact-card">
                    <Card.Body>
                      <h4>Our Office</h4>
                      <Stack direction="column" space={10}>
                        <a
                          className="address"
                          href="https://maps.google.com/?q="
                        >
                          TJ Mullen Electric Address
                        </a>
                        <Stack direction="column" space={5}>
                          <h6>Office Hours</h6>
                          <p>Mon-Fri 7am-5pm</p>
                        </Stack>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} lg={6} className="contact-card-col">
                  <Card className="contact-card">
                    <Card.Body>
                      <h4>Get In Touch</h4>
                      <Stack direction="column" space={10}>
                        <a href="mailto:tjmullenelectric@gmail.com">
                          tjmullenelectric@gmail.com
                        </a>
                        <a className="phone" href="tel:+1-781-686-6259">
                          781.686.6259
                        </a>
                        <Stack direction="row" space={5}>
                          <a
                            title="facebook page"
                            className="logo facebook-anchor"
                            href="https://www.facebook.com/"
                          ></a>
                          <a
                            title="instagram page"
                            className="logo instagram-anchor"
                            href="https://www.instagram.com/"
                          ></a>
                        </Stack>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Card className="contact-card">
                <Card.Body>
                  <QuoteForm />
                </Card.Body>
              </Card>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Contact };
