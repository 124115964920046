import * as React from "react";
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";

import { FormStepper } from "./FormStepper";
import { FormPersonalInfo } from "./FormPersonalInfo";
import { FormJobInfo } from "./FormJobInfo";
import { Stack } from "../../Stack";
import { PersonalInfoValues, JobInfoValues } from "../types";

import "./FormSteps.scss";

type QuoteValues = PersonalInfoValues | JobInfoValues;

const initialValue: QuoteValues = {
  name: "",
  email: "",
  phoneNumber: "",
  description: "",
  jobDescription: "",
  jobAddress: "",
  jobCity: "",
  jobState: "",
  jobZip: "",
};

interface FormStepsProps {
  onSuccess: () => void;
}

const FormSteps = ({ onSuccess }: FormStepsProps) => {
  const [quoteData, setQuoteData] = useState<QuoteValues>(initialValue);

  const handlePersonalSubmit = async (values: PersonalInfoValues) => {
    setQuoteData({ ...quoteData, ...values });
    console.log(values);
    try {
      //zam4i35byg.execute-api.us-east-1.amazonaws.com/prod
      https: await axios.post(
        "https://omkdf3y1n1.execute-api.us-east-1.amazonaws.com/prod/quote",
        {
          ...values,
        }
      );
      onSuccess();
    } catch (err) {
      console.log(err);
    }
  };

  const handleJobSubmit = (values: JobInfoValues) => {
    setQuoteData({ ...quoteData, ...values });
    console.log(quoteData);

    // make api call here

    onSuccess();
  };

  return (
    <>
      <h5 className="text-primary" style={{ fontWeight: "bold" }}>
        LETS GET SOME INFORMATION
      </h5>
      <Row>
        <Col xs={12} md={8}>
          <h6>
            Fill out the form to get in contact with us - we will reach out to
            you as soon as we can!
          </h6>
        </Col>
      </Row>
      <FormPersonalInfo onSubmit={handlePersonalSubmit} hide={false} />
      <Row>
        <Col>
          <Button type="submit" form={"form-1"} variant="primary">
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

const FormStepsMaintenance = ({ onSuccess }: FormStepsProps) => {
  return (
    <>
      <h5 className="text-primary" style={{ fontWeight: "bold" }}>
        UNDER CONSTRUCTION
      </h5>

      <Row>
        <Col xs={12} md={8}>
          <h6>
            We apologize our quote form is under construction and should be back
            up shortly. In the meantime feel free to call or email us directly
            for quotes.
            <Stack
              direction="column"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
              space={10}
            >
              <a href="mailto:">tjmullenelectric@gmail.com</a>
              <a className="phone" href="tel:+1-781-315-5321">
                781.315.5321
              </a>
            </Stack>
          </h6>
        </Col>
      </Row>
    </>
  );
};

export { FormSteps as FormSteps };
// export { FormStepsMaintenance as FormSteps };
