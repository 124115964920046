import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { NavBar, Footer, ScrollToTop } from "./components";
import { Home, About, Quote, Contact, Gallery, Services } from "./routes";

import "./App.scss";

const App = () => (
  <BrowserRouter>
    <ScrollToTop />
    <NavBar />

    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/quote">
        <Quote />
      </Route>
      <Route path="/gallery">
        <Gallery />
      </Route>
      <Route path="/services">
        <Services />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/*">
        <Home />
      </Route>
    </Switch>
    <Footer />
  </BrowserRouter>
);

export default App;
