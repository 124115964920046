import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

import "./ServiceImageCard.scss";

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
export const closeSpring = { type: "spring", stiffness: 300, damping: 35 };
interface ServiceImageCardProps {
  serviceTitle: string;
  imgUrl: string;
  imgAlt: string;
  layoutId: string;
  onClick: (layoutId: string) => void;
  description: string;
  isOpen?: boolean;
}

const ServiceImageCard = ({
  serviceTitle,
  description,
  imgAlt,
  imgUrl,
  layoutId,
  onClick,
  isOpen,
}: ServiceImageCardProps) => {
  const newClass = isOpen ? "open" : "closed";

  return (
    <div className={`card-content-container ${newClass}`}>
      <Card
        className="service-image-card card-content"
        onClick={() => onClick(layoutId)}
      >
        <Card.Body>
          <Row>
            <Col>
              <motion.img
                alt={imgAlt}
                className="service-image-card-image"
                src={imgUrl}
                layoutId={`card-${layoutId}`}
              />
              <motion.div
                layoutId={`card-overlay-${layoutId}`}
                className="service-image-card-overlay"
              ></motion.div>
              <Row className="service-image-card-details">
                <Col>
                  <motion.div layoutId={`card-details-${layoutId}`}>
                    <h3>{serviceTitle}</h3>
                    {isOpen && <motion.h5>{description}</motion.h5>}
                    {/* <motion.h5
                    initial={false}
                    layoutId={`card-description-${layoutId}`}
                    animate={{ display: isOpen ? "block" : "none" }}
                    transition={isOpen ? openSpring : closeSpring}
                    >
                      {description}
                    </motion.h5> */}
                  </motion.div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export { ServiceImageCard };
