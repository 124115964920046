import * as React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Jumbotron, Stack, Tiles, ServiceImageCard } from "../../components";
import { Helmet } from "react-helmet";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import { servicesList, ServiceInfo } from "./servicesList";

interface ItemProps {
  serviceTitle: string;
  onClick: () => void;
}

const Item = ({ serviceTitle, onClick }: ItemProps) => {
  const service = servicesList.find(
    (serv: ServiceInfo) => serv.title === serviceTitle
  );
  if (!service) {
    return null;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay-2"
      ></motion.div>
      <ServiceImageCard
        serviceTitle={service.title}
        imgUrl={service.imgUrl}
        imgAlt={service.imgAlt}
        layoutId={service.title}
        onClick={onClick}
        description={service.description}
        isOpen={true}
      />
    </>
  );
};

const Services = () => {
  const [selectedService, setSelectedService] = useState<string | null>(null);

  const handleOnClick = (title: string) => {
    setSelectedService(title);
  };

  const handleItemClick = () => {
    setSelectedService(null);
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Services - TJ Mullen Electric</title>
        <meta name="og:title" content="Services - TJ Mullen Electric" />
        <meta
          name="description"
          content="TJ Mullen Electric Offered Services"
        />
        <meta
          name="og:description"
          content="TJ Mullen Electric Offered Services"
        />
      </Helmet>
      <Jumbotron
        header="SERVICES"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Stack direction="column" space={80}>
              <div>
                <Row>
                  <Col xs={12} md={7}>
                    <h3
                      className="text-primary"
                      style={{ fontWeight: "bold", marginBottom: "0" }}
                    >
                      OUR SERVICES
                    </h3>
                    <p className="sub-header">
                      We offer a wide range of electrical services check below
                      to find what you are looking for
                    </p>
                  </Col>
                </Row>
              </div>
            </Stack>
            <AnimateSharedLayout type="crossfade">
              <Stack
                direction="column"
                space={100}
                sx={{ marginBottom: "100px", marginTop: "20px" }}
              >
                <Tiles columns={[1, 2, 3]} space="16px">
                  {servicesList.map((service: ServiceInfo, index: number) => {
                    return (
                      <ServiceImageCard
                        serviceTitle={service.title}
                        imgUrl={service.imgUrl}
                        imgAlt={service.imgAlt}
                        layoutId={service.title}
                        onClick={handleOnClick}
                        description={service.description}
                      />
                    );
                  })}
                </Tiles>
              </Stack>
              <AnimatePresence>
                {selectedService && (
                  <Item
                    serviceTitle={selectedService}
                    onClick={handleItemClick}
                  />
                )}
              </AnimatePresence>
            </AnimateSharedLayout>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Services };
