import * as React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Jumbotron, Stack, Tiles, ImageCard } from "../../components";
import { Helmet } from "react-helmet";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import "./gallery.scss";
import gallery1 from "../../images/gallery-1.jpeg";
import gallery2 from "../../images/gallery-2.jpeg";
import gallery3 from "../../images/gallery-3.jpeg";
import gallery4 from "../../images/gallery-4.jpeg";

import gallery1Small from "../../images/gallery-1-small.jpeg";
import gallery2Small from "../../images/gallery-2-small.jpeg";
import gallery3Small from "../../images/gallery-3-small.jpeg";
import gallery4Small from "../../images/gallery-4-small.jpeg";

const smallImages = [
  gallery1Small,
  gallery2Small,
  gallery3Small,
  gallery4Small,
];
const images = [gallery1, gallery2, gallery3, gallery4];

interface ItemProps {
  id: string;
  onClick: () => void;
}

const Item = ({ id, onClick }: ItemProps) => {
  const image = images[parseInt(id)];
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay-2"
      ></motion.div>
      <ImageCard
        title=""
        href=""
        imgUrl={image}
        imgAlt="alt 1"
        layoutId={id}
        onClick={onClick}
        isOpen={true}
      />
    </>
  );
};

const Gallery = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleOnClick = (id: string) => {
    setSelectedId(id);
  };

  const handleItemClick = () => {
    setSelectedId(null);
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Gallery - TJ Mullen Electric</title>
        <meta name="og:title" content="Gallery - TJ Mullen Electric" />
        <meta
          name="description"
          content="TJ Mullen Electric Recent Work Gallery!"
        />
        <meta
          name="og:description"
          content="TJ Mullen Electric Recent Work Gallery!"
        />
      </Helmet>
      <Jumbotron
        header="GALLERY"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Stack direction="column" space={80}>
              <div>
                <Row>
                  <Col xs={12} md={7}>
                    <h3
                      className="text-primary"
                      style={{ fontWeight: "bold", marginBottom: "0" }}
                    >
                      OUR WORK
                    </h3>
                    <p className="sub-header">
                      Check out some of our most recent installs
                    </p>
                  </Col>
                </Row>
              </div>
            </Stack>
            <AnimateSharedLayout type="crossfade">
              <Stack
                direction="column"
                space={100}
                sx={{ marginBottom: "100px", marginTop: "20px" }}
              >
                <Tiles columns={[1, 2, 3]} space="16px">
                  {smallImages.map((image: string, index: number) => {
                    return (
                      <ImageCard
                        title=""
                        href=""
                        key={index}
                        imgUrl={image}
                        imgAlt="alt 1"
                        layoutId={index.toString()}
                        onClick={handleOnClick}
                      />
                    );
                  })}
                </Tiles>
              </Stack>
              <AnimatePresence>
                {selectedId && (
                  <Item id={selectedId} onClick={handleItemClick} />
                )}
              </AnimatePresence>
            </AnimateSharedLayout>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Gallery };
